import React from 'react'
import styled from 'styled-components'
import { ExperienceButton } from 'components/group/index'
import {
  rem,
  color,
  innerWidth,
  fontFamily,
  breakpoints,
  innerPadding,
  innerDummyPadding,
} from 'variables/_index'

export interface CourseItemLayoutProps {
  readonly id: string
  readonly headElement: JSX.Element
  readonly imageElement: JSX.Element
  readonly boxItemsElement: JSX.Element
  readonly moreButtonElement: JSX.Element
}

type CourseItemLayoutPropsClassName = CourseItemLayoutProps & {
  readonly className: string
}

function Component({
  id,
  className,
  headElement,
  imageElement,
  boxItemsElement,
  moreButtonElement,
}: CourseItemLayoutPropsClassName) {
  return (
    <div className={className}>
      <div className="e-head">{headElement}</div>
      <div className="e-body">
        <div className="e-inner">
          <div className="e-image">{imageElement}</div>
          <div className="e-content">{boxItemsElement}</div>
          <div className="e-experience">
            <ExperienceButton id={id} />
          </div>
        </div>
      </div>
      <div className="e-button">{moreButtonElement}</div>
    </div>
  )
}

const StyledComponent = styled(Component)<CourseItemLayoutPropsClassName>`
  > .e-head {
  }

  > .e-body {
    margin-top: ${rem(60 / 2)};
    padding-bottom: ${rem((60 + 35) / 2)};
    background-color: ${color.mainBackground};

    @media ${breakpoints.lg} {
      padding-bottom: ${rem(60)};
    }

    > .e-inner {
      width: calc(${innerWidth.sm} + ${rem((10 / 2) * 2)});
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      position: relative;

      @media ${breakpoints.lg} {
        width: ${innerWidth.lg};
        padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
        padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      }

      > .e-image {
        @media ${breakpoints.lg} {
          text-align: center;
          background-color: #fff;
        }

        img {
          width: 100%;

          @media ${breakpoints.lg} {
            width: ${rem(720)};
            max-width: 100%;
          }
        }
      }

      > .e-content {
        padding: ${rem(80 / 2)} ${rem(50 / 2)};
        background-color: #fff;

        > .e-items {
          @media ${breakpoints.lg} {
            width: ${rem(720)};
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
          }

          > .e-item {
            @media ${breakpoints.lg} {
            }

            &:nth-child(n + 2) {
              margin-top: ${rem(120 / 2)};
            }

            > .e-box {
              > .e-box-head {
                display: flex;
                justify-content: center;
                font-family: ${fontFamily.zenKaku};
                font-weight: 500;
                font-size: ${rem(42 / 2)};
                line-height: 1.428;
                color: ${color.main};
                text-align: center;

                &::before,
                &::after {
                  content: '｜';
                  flex-shrink: 0;
                }
              }

              > .e-box-body {
                margin-top: ${rem(40 / 2)};
                font-size: ${rem(32 / 2)};
                line-height: 2;
              }

              > .e-box-lists {
                margin-top: ${rem(40 / 2)};

                > .e-box-list {
                  padding-top: ${rem(16 / 2)};
                  padding-bottom: ${rem(16 / 2)};
                  border-top: solid 1px ${color.main};
                  font-size: ${rem(32 / 2)};
                  line-height: 1.69;

                  &:last-child {
                    border-bottom: solid 1px ${color.main};
                  }

                  > .e-small {
                    font-size: ${rem(24 / 2)};
                  }
                }

                &.is-text-left {
                  > .e-box-list {
                    text-align: left;
                  }
                }

                &.is-text-center {
                  > .e-box-list {
                    text-align: center;
                  }
                }
              }

              > .e-box-text-lists {
                margin: ${rem(40 / 2)} 0 0;
                padding-top: ${rem(40 / 2)};
                padding-bottom: ${rem(40 / 2)};
                border-top: solid 1px ${color.main};
                border-bottom: solid 1px ${color.main};

                > .e-box-text-list {
                  margin: 0 0 0 ${rem(20)};
                  padding: 0;
                  line-height: 2;
                }
              }
            }
          }
        }
      }

      > .e-experience {
        margin-top: ${rem(40 / 2)};
        text-align: center;

        @media ${breakpoints.lg} {
          margin-top: ${rem(30)};
        }
      }
    }
  }

  > .e-button {
    margin-top: ${rem(-70 / 2 / 2)};
    text-align: center;

    @media ${breakpoints.lg} {
      margin-top: ${rem(-62 / 2)};
    }
  }
`

export const CourseItemLayout: (props: CourseItemLayoutProps) => JSX.Element =
  StyledComponent

export default CourseItemLayout
