import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { SectionHeadingPage, ButtonSmall } from 'components/atoms/index'
import { CourseNav } from 'components/section/common/courseNav'
import { rem, breakpoints, fixedHeaderHeight } from 'variables/_index'
import CourseHead from './course/courseHead'
import { CourseItemLayout } from './course/courseItemLayout'
import { siteStructure } from 'utils/site'

export interface CourseBaseSectionProps {
  readonly noProps?: string
}

type CourseBaseSectionPropsClassName = CourseBaseSectionProps & {
  readonly className: string
}

function Component({ className }: CourseBaseSectionPropsClassName) {
  return (
    <div className={className}>
      <div className="e-head">
        <SectionHeadingPage textElement={<>コースの料金</>} headingLevel="h2" />
      </div>
      <div className="e-nav">
        <CourseNav />
      </div>
      <div className="e-items">
        <div id="kids" className="e-item">
          <CourseItemLayout
            id="cta_content1_trial"
            headElement={<CourseHead popText="勉強って面白い！" titleText="こどもコースの料金" yearText="｜年⻑〜⼩２｜" headingLevel="h3" />}
            imageElement={<StaticImage src="../../../assets/images/fee/kids.jpg" alt="" />}
            // TODO: e-itemsをコンポーネント化したい
            boxItemsElement={
              <div className="e-items">
                <div className="e-item">
                  <div className="e-box">
                    <h4 className="e-box-head">算数・英会話</h4>
                    <div className="e-box-body">1回20分の通常レッスン / 選択レッスンが受講できます。</div>
                    <div className="e-box-lists is-text-center">
                      <div className="e-box-list">1教科あたり　4,950円／月</div>
                    </div>
                  </div>
                </div>
                <div className="e-item">
                  {/* TODO: e-boxをコンポーネントとしてまとめたい。JSX.Elementの配列作る必要あり？ */}
                  <div className="e-box">
                    <h4 className="e-box-head">その他の費用</h4>
                    <div className="e-box-lists is-text-left">
                      <div className="e-box-list">入会金｜11,000円</div>
                      <div className="e-box-list">教材費｜2,200円<span className="e-small">（こども算数のみ）</span></div>
                      <div className="e-box-list">
                        学力診断テスト
                        <br />
                        1回｜2,400円
                        <span className="e-small">（こども算数のみ）</span>
                      </div>
                      <div className="e-box-list">諸経費｜月額 220円</div>
                    </div>
                    <div className="e-box-body">2コースを同時受講される場合は月謝割引があります。</div>
                  </div>
                </div>
              </div>
            }
            moreButtonElement={<ButtonSmall href={siteStructure.kids.path} value="こどもコースを詳しくみる" />}
          />
        </div>
        <div id="primary" className="e-item">
          <CourseItemLayout
            id="cta_content2_trial"
            headElement={<CourseHead popText="基礎学力が身につく！" titleText="小学生コースの料金" yearText="｜⼩３〜⼩６｜" headingLevel="h3" />}
            imageElement={<StaticImage src="../../../assets/images/fee/primary.jpg" alt="" />}
            boxItemsElement={
              <div className="e-items">
                <div className="e-item">
                  <div className="e-box">
                    <h4 className="e-box-head">小学３年生</h4>
                    <div className="e-box-lists is-text-center">
                      <div className="e-box-list">週1回｜11,000円／⽉</div>
                      <div className="e-box-list">週2回｜16,500円／⽉</div>
                      <div className="e-box-list">週3回｜22,000円／⽉</div>
                    </div>
                  </div>
                </div>
                <div className="e-item">
                  <div className="e-box">
                    <h4 className="e-box-head">小学４年生〜６年生</h4>
                    <div className="e-box-lists is-text-center">
                      <div className="e-box-list">週1回｜13,200円／⽉</div>
                      <div className="e-box-list">週2回｜18,700円／⽉</div>
                      <div className="e-box-list">週3回｜24,200円／⽉</div>
                    </div>
                  </div>
                </div>
                <div className="e-item">
                  <div className="e-box">
                    <h4 className="e-box-head">月額料金に含むサービス</h4>
                    <ul className="e-box-text-lists">
                      <li className="e-box-text-list">
                        個別スタディ
                        <br />
                        週1回｜50分｜講師1人・生徒2人
                      </li>
                      <li className="e-box-text-list">受け放題の集団ライブ授業</li>
                      <li className="e-box-text-list">いつでも見られる映像授業</li>
                      <li className="e-box-text-list">スタディルーム（自習室）</li>
                      <li className="e-box-text-list">質問できる解説ルーム</li>
                      <li className="e-box-text-list">担任による個別コーチング（月1回）</li>
                    </ul>
                  </div>
                </div>
                <div className="e-item">
                  <div className="e-box">
                    <h4 className="e-box-head">その他の費用</h4>
                    <div className="e-box-lists is-text-left">
                      <div className="e-box-list">⼊会⾦｜22,000円</div>
                      <div className="e-box-list">教材費｜1教科｜2,400円～</div>
                      <div className="e-box-list">
                        学力診断テスト
                        <br />
                        1回（⼩3）｜2,400円
                        <br />
                        1回（⼩4）｜2,640円
                        <br />
                        1回（⼩5・⼩6）｜3,300円
                      </div>
                      <div className="e-box-list">諸経費｜月額 2,420円</div>
                    </div>
                  </div>
                </div>
              </div>
            }
            moreButtonElement={<ButtonSmall href={siteStructure.primary.path} value="小学生コースを詳しくみる" />}
          />
        </div>
        <div id="junior" className="e-item">
          <CourseItemLayout
            id="cta_content3_trial"
            headElement={<CourseHead popText="成績アップを実現！" titleText="中学生コースの料金" headingLevel="h3" />}
            imageElement={<StaticImage src="../../../assets/images/fee/junior.jpg" alt="" />}
            boxItemsElement={
              <div className="e-items">
                <div className="e-item">
                  <div className="e-box">
                    <h4 className="e-box-head">中学１・２年生</h4>
                    <div className="e-box-lists is-text-center">
                      <div className="e-box-list">週1回｜14,400円／⽉</div>
                      <div className="e-box-list">週2回｜28,600円／⽉</div>
                      <div className="e-box-list">週3回｜42,800円／⽉</div>
                    </div>
                  </div>
                </div>
                <div className="e-item">
                  <div className="e-box">
                    <h4 className="e-box-head">中学３年生</h4>
                    <div className="e-box-lists is-text-center">
                      <div className="e-box-list">週1回｜16,800円／⽉</div>
                      <div className="e-box-list">週2回｜31,000円／⽉</div>
                      <div className="e-box-list">週3回｜45,200円／⽉</div>
                    </div>
                  </div>
                </div>
                <div className="e-item">
                  <div className="e-box">
                    <h4 className="e-box-head">月額料金に含むサービス</h4>
                    <ul className="e-box-text-lists">
                      <li className="e-box-text-list">
                        個別スタディ
                        <br />
                        週1回｜80分｜講師1人・生徒2人
                      </li>
                      <li className="e-box-text-list">スタディルーム（自習室）</li>
                      <li className="e-box-text-list">質問できる解説ルーム</li>
                      <li className="e-box-text-list">単元テストルーム</li>
                      <li className="e-box-text-list">担任による個別コーチング（⽉1回）</li>
                    </ul>
                  </div>
                </div>
                <div className="e-item">
                  <div className="e-box">
                    <h4 className="e-box-head">その他の費用</h4>
                    <div className="e-box-lists is-text-left">
                      <div className="e-box-list">⼊会⾦｜22,000円</div>
                      <div className="e-box-list">教材費｜1教科｜2,400円〜</div>
                      <div className="e-box-list">諸経費｜月額 3,200円</div>
                    </div>
                  </div>
                </div>
              </div>
            }
            moreButtonElement={<ButtonSmall href={siteStructure.junior.path} value="中学生コースを詳しくみる" />}
          />
        </div>
        <div id="high" className="e-item">
          <CourseItemLayout
            id="cta_content4_trial"
            headElement={<CourseHead popText="受験の基礎学力アップ！" titleText="高校生コースの料金" headingLevel="h3" />}
            imageElement={<StaticImage src="../../../assets/images/fee/high.jpg" alt="" />}
            boxItemsElement={
              <div className="e-items">
                <div className="e-item">
                  <div className="e-box">
                    <div className="e-box-lists is-text-center">
                      <div className="e-box-list">週1回｜19,800円／⽉</div>
                      <div className="e-box-list">週2回｜32,600円／⽉</div>
                      <div className="e-box-list">週3回｜45,200円／⽉</div>
                    </div>
                  </div>
                </div>
                <div className="e-item">
                  <div className="e-box">
                    <h4 className="e-box-head">月額料金に含むサービス</h4>
                    <ul className="e-box-text-lists">
                      <li className="e-box-text-list">
                        個別スタディ
                        <br />
                        週1回｜80分｜講師1人・生徒2人
                      </li>
                      <li className="e-box-text-list">スタディルーム（自習室）</li>
                      <li className="e-box-text-list">質問できる解説ルーム</li>
                      <li className="e-box-text-list">単元テストルーム</li>
                      <li className="e-box-text-list">担任による個別コーチング（⽉1回）</li>
                    </ul>
                  </div>
                </div>
                <div className="e-item">
                  <div className="e-box">
                    <h4 className="e-box-head">その他の費用</h4>
                    <div className="e-box-lists is-text-left">
                      <div className="e-box-list">⼊会⾦｜22,000円</div>
                      <div className="e-box-list">教材費｜1教科｜2,400円～</div>
                      <div className="e-box-list">諸経費｜月額 2,420円</div>
                    </div>
                  </div>
                </div>
              </div>
            }
            moreButtonElement={<ButtonSmall href={siteStructure.high.path} value="高校生コースを詳しくみる" />}
          />
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CourseBaseSectionPropsClassName>`
  scroll-behavior: smooth;

  > .e-head {
    text-align: center;
  }

  > .e-nav {
    margin-top: ${rem(60 / 2)};

    @media ${breakpoints.lg} {
    }
  }

  > .e-items {
    margin-top: ${rem(160 / 2)};

    > .e-item {
      scroll-margin-top: ${fixedHeaderHeight.sm}; // 固定ヘッダーの高さ

      @media ${breakpoints.lg} {
        scroll-margin-top: calc(${fixedHeaderHeight.lg} + ${rem(30)});
      }

      &:nth-child(n + 2) {
        margin-top: ${rem(200 / 2)};
      }
    }
  }
`

export const CourseBaseSection: (props: CourseBaseSectionProps) => JSX.Element = StyledComponent

export default CourseBaseSection
