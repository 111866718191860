import React from 'react'
import styled from 'styled-components'
import { UnderBarHeading } from 'components/atoms/index'
import {
  rem,
  innerWidth,
  color,
  breakpoints,
  innerPadding,
  innerDummyPadding,
} from 'variables/_index'

export interface SummarySectionProps {
  readonly noProps?: string
}

type SummarySectionPropsClassName = SummarySectionProps & {
  readonly className: string
}

function Component({ className }: SummarySectionPropsClassName) {
  return (
    <div className={className}>
      <div className="e-inner">
        <div className="e-head">
          <UnderBarHeading text="料金の概要" headingLevel="h2" />
        </div>
        <div className="e-body">
          <div className="e-lists is-text-center">
            <div className="e-list">こどもコース｜ 4,950円〜</div>
            <div className="e-list">小学生コース｜11,000円〜</div>
            <div className="e-list">中学生コース｜14,400円〜</div>
            <div className="e-list">高校生コース｜19,800円〜</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<SummarySectionPropsClassName>`
  padding-top: ${rem(200 / 2)};
  padding-bottom: ${rem(160 / 2)};

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: ${rem(50 / 2)};
    padding-right: ${rem(50 / 2)};

    @media ${breakpoints.lg} {
      width: ${rem(750 + innerDummyPadding.lg * 2 + innerPadding.lg * 2)};
      padding-left: ${rem(innerDummyPadding.lg + innerPadding.lg)};
      padding-right: ${rem(innerDummyPadding.lg + innerPadding.lg)};
    }

    > .e-head {
    }

    > .e-body {
      margin-top: ${rem(60 / 2)};

      > .e-lists {
        > .e-list {
          padding-top: ${rem(16 / 2)};
          padding-bottom: ${rem(16 / 2)};
          border-top: solid 1px ${color.main};
          font-size: ${rem(32 / 2)};
          line-height: 1.69;

          &:last-child {
            border-bottom: solid 1px ${color.main};
          }

          > .e-small {
            font-size: ${rem(24 / 2)};
          }
        }

        &.is-text-center {
          > .e-list {
            text-align: center;
          }
        }
      }
    }
  }
`

export const SummarySection: (props: SummarySectionProps) => JSX.Element = StyledComponent

export default SummarySection
