import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { CourseLinkCard } from 'components/atoms/index'
import { innerWidth, color, rem, breakpoints, innerPadding, innerDummyPadding } from 'variables/_index'
import { siteStructure } from 'utils/site'

export interface CourseNavProps {
  readonly isLink?: boolean
}

type CourseNavClassName = CourseNavProps & {
  readonly className: string
}

function Component({ className, isLink }: CourseNavClassName) {
  return (
    <div className={className}>
      <div className="e-inner">
        <div className="e-course-links">
          <div className="e-course-link">
            <CourseLinkCard
              imageElement={<StaticImage src="../../../assets/images/course/course1-rectangle.jpg" alt="" placeholder="none" />}
              courseName="こども"
              courseYear="年長～小２"
              href={isLink ? siteStructure.kids.path : 'kids'}
              isLink={isLink}
            />
          </div>
          <div className="e-course-link">
            <CourseLinkCard
              imageElement={<StaticImage src="../../../assets/images/course/course2-rectangle.jpg" alt="" placeholder="none" />}
              courseName="小学生"
              courseYear="小３～小６"
              href={isLink ? siteStructure.primary.path : 'primary'}
              isLink={isLink}
            />
          </div>
          <div className="e-course-link">
            <CourseLinkCard
              imageElement={<StaticImage src="../../../assets/images/course/course3-rectangle.jpg" alt="" placeholder="none" />}
              courseName="中学生"
              href={isLink ? siteStructure.junior.path : 'junior'}
              isLink={isLink}
            />
          </div>
          <div className="e-course-link">
            <CourseLinkCard
              imageElement={<StaticImage src="../../../assets/images/course/course4-rectangle.jpg" alt="" placeholder="none" />}
              courseName="高校生"
              href={isLink ? siteStructure.high.path : 'high'}
              isLink={isLink}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CourseNavClassName>`
  border-top: 1px solid ${color.main};
  border-bottom: 1px solid ${color.main};
  position: relative;

  &::before {
    content: '';
    height: 1px;
    width: 100%;
    background-color: ${color.main};
    top: ${rem(150 / 2)};
    left: 0;
    position: absolute;

    @media ${breakpoints.lg} {
      top: ${rem(198)};
    }
  }

  > .e-inner {
    width: ${rem(375)};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-course-links {
      display: flex;
      position: relative;
      z-index: 2;

      > .e-course-link {
        width: 25%;

        &:not(:first-child) {
          border-left: 1px solid ${color.main};
        }

        &:first-child {
          @media (min-width: 376px) {
            border-left: 1px solid ${color.main};
          }
        }

        &:last-child {
          @media (min-width: 376px) {
            border-right: 1px solid ${color.main};
          }
        }

        > a {
          height: 100%;
        }

        img {
          width: ${rem(188 / 2)};
          height: ${rem(150 / 2)};

          @media ${breakpoints.lg} {
            width: ${rem(248)};
            height: ${rem(198)};
          }
        }
      }
    }
  }
`

export const CourseNav: (props: CourseNavProps) => JSX.Element = StyledComponent

export default CourseNav
