import React from 'react'
import styled from 'styled-components'
import HeadSection from 'components/section/headSection'
import LeadSection from 'components/section/fee/leadSection'
import SummarySection from 'components/section/fee/summarySection'
import CourseBaseSection from 'components/section/fee/courseBaseSection'
import {} from 'variables/_index'

export interface FeeTemplateProps {
  readonly headingText: string
}

type FeeTemplatePropsClassName = FeeTemplateProps & {
  readonly className: string
}

function Component({ className, headingText }: FeeTemplatePropsClassName) {
  return (
    <div className={className}>
      <HeadSection
        pageHeadProps={{
          headingText: headingText,
          headingLevel: 'h1',
          isWide: true,
        }}
      />
      <LeadSection />
      <CourseBaseSection />
      <SummarySection />
    </div>
  )
}

const StyledComponent = styled(Component)<FeeTemplatePropsClassName>``

export const FeeTemplate: (props: FeeTemplateProps) => JSX.Element =
  StyledComponent

export default FeeTemplate
