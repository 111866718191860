import React from 'react'
import styled from 'styled-components'
import { rem, color, fontFamily, breakpoints } from 'variables/_index'

export interface CourseHeadProps {
  readonly popText: string
  readonly titleText: string
  readonly yearText?: string
  readonly headingLevel: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

type CourseHeadPropsClassName = CourseHeadProps & {
  readonly className: string
}

function Component({
  className,
  popText,
  titleText,
  yearText,
  headingLevel,
}: CourseHeadPropsClassName) {
  const HeadingElement = headingLevel

  return (
    <div className={className}>
      <div className="e-pop">{popText}</div>
      <HeadingElement className="e-title">{titleText}</HeadingElement>
      {yearText && <div className="e-year">{yearText}</div>}
    </div>
  )
}

const StyledComponent = styled(Component)<CourseHeadPropsClassName>`
  text-align: center;

  > .e-pop {
    font-size: ${rem(34 / 2)};
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: ${fontFamily.zenKaku};

    @media ${breakpoints.lg} {
      font-size: ${rem(20)};
    }

    &::before,
    &::after {
      content: '';
      display: inline-block;
      flex-shrink: 0;
      height: ${rem(20)};
      width: 1px;
      background-color: ${color.main};
    }

    &::before {
      margin-right: ${rem(10)};
      transform: rotate(-12deg);
    }

    &::after {
      margin-left: ${rem(10)};
      transform: rotate(12deg);
    }
  }

  > .e-title {
    margin-top: ${rem(10 / 2)};
    font-family: ${fontFamily.zenKaku};
    font-size: ${rem(46 / 2)};
    font-weight: 500;
    color: ${color.main};

    @media ${breakpoints.lg} {
      font-size: ${rem(30)};
    }
  }

  > .e-year {
    margin-top: ${rem(10 / 2)};
    font-family: ${fontFamily.zenKaku};
    font-size: ${rem(32 / 2)};
    font-weight: 700;
    color: ${color.main};

    @media ${breakpoints.lg} {
      font-size: ${rem(20)};
    }
  }
`

export const CourseHead: (props: CourseHeadProps) => JSX.Element =
  StyledComponent

export default CourseHead
