import React from 'react'
import Layout from 'layout/base'
import Seo from 'components/seo'
import type { TypeBreadcrumb } from 'types/breadcrumb'
import { siteStructure } from 'utils/site'
import FeeTemplate from 'templates/feeTemplate'
import globalTitle from 'utils/globalTitle'

interface FeePageProps {
  pageContext: TypeBreadcrumb
  location: Location
}

const FeePage = ({ pageContext, location }: FeePageProps): JSX.Element => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  // TODO : 全ページで作っているので共通化したい
  const breadcrumbItems = crumbs.map(crumb => {
    return crumb.crumbLabel in siteStructure
      ? {
          ...crumb,
          ...{ crumbLabel: siteStructure[crumb.crumbLabel]?.name },
        }
      : { ...crumb }
  })

  return (
    <Layout pageType="page" breadcrumbItems={breadcrumbItems} ctaType="type1">
      <Seo
        title={`料金${globalTitle(location.pathname)}`}
        description="年⻑から高校生まで4つのコースをご⽤意。入学金、授業料、教材費や諸経費までご確認いただけます。"
        path={location.pathname}
      />
      <FeeTemplate headingText="料金" />
    </Layout>
  )
}

export default FeePage
