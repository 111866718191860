import React from 'react'
import styled from 'styled-components'
import { rem, breakpoints } from 'variables/_index'

export interface LeadProps {
  readonly noProps?: string
}

type LeadPropsClassName = LeadProps & {
  readonly className: string
}

function Component({ className }: LeadPropsClassName) {
  return (
    <div className={className}>
      <div className="e-text">
        年⻑から高校生まで
        <br className="u-hidden-lg" />
        4つのコースをご⽤意。
      </div>
      <div className="e-sup">※すべて税込み表記です。</div>
    </div>
  )
}

const StyledComponent = styled(Component)<LeadPropsClassName>`
  padding-top: ${rem(80 / 2)};
  padding-bottom: ${rem(80 / 2)};
  text-align: center;

  @media ${breakpoints.lg} {
  }

  > .e-text {
    font-size: ${rem(36 / 2)};
    line-height: 1.83;

    @media ${breakpoints.lg} {
      font-size: ${rem(24)};
    }
  }

  > .e-sup {
    margin-top: ${rem(20 / 2)};
    font-size: ${rem(24 / 2)};

    @media ${breakpoints.lg} {
      font-size: ${rem(16)};
    }
  }
`

export const Lead: (props: LeadProps) => JSX.Element = StyledComponent

export default Lead
